import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { routing } from './header.routing.module';
import { HeaderService } from './header.service';


@NgModule({
  imports: [
    SharedModule,
    routing,
    NgbModule.forRoot(),
  ],
  declarations: [
    HeaderComponent,
  ],
  exports: [HeaderComponent],
  providers: [
    HeaderService
  ]
})
export class HeaderModule { }
