import {RouterModule,Routes} from '@angular/router';
import { ModuleWithProviders } from '@angular/core';


const routes: Routes = [
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);


