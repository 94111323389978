import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { config } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private getProfileUrl = config.getEnvironmentVariable('endPoint') + 'api/user/detail';
  constructor(private http: HttpClient) {
  }

  getProfile(): Observable<any> {
    let params = new HttpParams();
    params = params.append('token', localStorage.getItem('charity_admin_token'));
    return this.http.get<any>(this.getProfileUrl, { params: params, observe: 'response' });
  }
}
