import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { appRouter } from './app-routing.module';
import { AppComponent } from './app.component';
// import { SidebarComponent } from './sidebar/sidebar.component';
// import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './auth-guard.service';
import { HeaderModule } from './header/header.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { EventsModule } from 'angular4-events';

@NgModule({
  declarations: [
    AppComponent,
    // UserCardsComponent,
    // BannerComponent,
    // TransactionsComponent,
    // ValidationErrorsComponent,
    // SidebarComponent,
    // HeaderComponent
  ],
  imports: [
    BrowserModule,
    appRouter,
    FormsModule,
    HttpClientModule,
    NgHttpLoaderModule,
    BrowserAnimationsModule,
    HeaderModule,
    SidebarModule,
    // ValidationModule,
    NgbModule.forRoot(),
    EventsModule.forRoot(),
  ],
  providers: [
    AuthGuard
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
