import { NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from '../AuthInterceptor';
import {NgHttpLoaderModule} from 'ng-http-loader';
import { ToastrModule } from 'ngx-toastr';


export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];



@NgModule({
  declarations: [],
  imports :[
    NgbModule.forRoot(),
    HttpClientModule,
    NgHttpLoaderModule,
    ToastrModule.forRoot()
  ],

  exports:[
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers:[
    httpInterceptorProviders
  ]
})
export class SharedModule { }
