import { Component ,DoCheck } from '@angular/core';
import {Location} from "@angular/common";
import {Router, NavigationEnd, ActivatedRoute} from "@angular/router";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements DoCheck {
  isToken = false;
  isSide = false;
  isPayment = false;

  constructor(public location: Location, titleService: Title, router: Router){
    var self = this;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        var title = self.getTitle(router.routerState, router.routerState.root).join('-');
        titleService.setTitle(title);
      }
    });
  }
  ngDoCheck() {
    const route = this.location.path().split('/');
    if (route[1] == null || route[1] == 'user-register' ) {
      this.isToken = true;
    }
    else {
      this.isToken = false;
    }
    if (route[1] == 'add-card' ) {
      this.isPayment = true;
    }else{
      this.isPayment = false;
    }
  }

  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

}
