import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth-guard.service';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/' },
  { path: '', loadChildren: './login/login.module#LoginModule' },
  { path: 'home', loadChildren: './home/home.module#HomeModule', canActivate: [AuthGuard] },
  { path: 'users', loadChildren: './user/user.module#UserModule', canActivate: [AuthGuard] },
  { path: 'news', loadChildren: './news/news.module#NewsModule', canActivate: [AuthGuard] },
  { path: 'notification', loadChildren: './notification/notification.module#NotificationModule', canActivate: [AuthGuard] },
  { path: 'schedule-notification', loadChildren: './noti-schedule/noti-schedule.module#NotischeduleModule', canActivate: [AuthGuard] },
  { path: 'user-home', loadChildren: './user-home/user-home.module#UserHomeModule', canActivate: [AuthGuard] },
  { path: 'user-news', loadChildren: './user-news/user-news.module#UserNewsModule', canActivate: [AuthGuard] },
  { path: 'user-payment', loadChildren: './user-payment/user-payment.module#UserPaymentModule', canActivate: [AuthGuard] },
  { path: 'add-card', loadChildren: './user-payment/user-payment.module#UserPaymentModule' },
  { path: 'user-register', loadChildren: './user-register/user-register.module#UserRegisterModule' },
  { path: "recurring", loadChildren: './recurring/recurring.module#RecurringModule', canActivate: [AuthGuard] },
  { path: "transactions", loadChildren: './transactions/transactions.module#TransactionsModule', canActivate: [AuthGuard] },
  { path: "banners", loadChildren: './banner/banner.module#BannerModule', canActivate: [AuthGuard] },
  { path: "cards", loadChildren: './user-cards/user-cards.module#UserCardsModule', canActivate: [AuthGuard] },
];

export const appRouter: ModuleWithProviders = RouterModule.forRoot(routes);
