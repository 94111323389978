import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {routing} from './sidebar.routing.module';
import {SharedModule} from '../shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LoginService} from '../login/login.service';
import {SidebarComponent} from './sidebar.component';
import {EventManager} from '../event-handler.service';

@NgModule({
  imports: [
    SharedModule,
    routing,
    CommonModule,
    NgbModule.forRoot()
  ],
  declarations: [
    SidebarComponent
],
  exports: [SidebarComponent],
  providers : [
    LoginService,
    EventManager
  ],
})
export class SidebarModule {
}
