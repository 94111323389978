import {Component, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {Subscription} from 'rxjs';
import {EventManager} from '../event-handler.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  roleType;
  eventSubscriber:  Subscription;
  constructor(public eventManager: EventManager) {
  }


  ngOnInit() {
    this.updateData();
    this.roleType = localStorage.getItem('charity_role_type');
  }
  updateData() {
    this.eventSubscriber = this.eventManager.subscribe('updateHeader', (response) => {
      console.log(response);
      this.roleType = localStorage.getItem('charity_role_type');
      });
  }


}
