export class config {
  public static getEnvironmentVariable(value) {
    // let serverip = 'http://backend.test-charity.cf/';
    // let serverip = 'http://127.0.0.1:8000/';
    // let serverip = 'http://localhost:8000/';
    // let serverip = "https://backend.tzedakaumarpe.smartwave.website/";
    let serverip = "https://backend.tzedakaumarpe.com/";
    // let serverip =  "http://127.0.0.1:8000/";
    return serverip;
  }
}
