import {HttpResponse, HttpHeaders, HttpRequest} from '@angular/common/http';
import {config} from '../app.config';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LoginResponseModal} from './login.modal';

@Injectable()
export class LoginService {

  private loginUrl = config.getEnvironmentVariable('endPoint') + 'api/auth/login';
  constructor(private http: HttpClient) {
  }

  login(data): Observable<LoginResponseModal> {
    return this.http.post<LoginResponseModal>(this.loginUrl, data);
  }

}



