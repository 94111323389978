import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from './header.service';
import { Subscription } from 'rxjs';
import { EventManager } from '../event-handler.service';
import { EventsService } from 'angular4-events';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  headerText = true;
  error;
  userName;
  eventSubscriber: Subscription;

  constructor(private route: Router, private headerService: HeaderService, public eventManager: EventManager,
    private events: EventsService
  ) { }

  ngOnInit() {
    this.updateData();
    if (localStorage.getItem('charity_admin_token')) {
      this.getProfile();
    }
  }

  logout() {
    localStorage.removeItem('charity_admin_token');
    this.route.navigate(['']);
  }

  getProfile() {
    this.headerService.getProfile().subscribe(response => {
      this.userName = response.body.first_name;
      this.events.publish("user-details", response.body);
    }, error => {
      console.log(error);
      this.error = JSON.parse(error._body);
    });
  }
  updateData() {
    this.eventSubscriber = this.eventManager.subscribe('updateHeader', (response) => {
      console.log(response);
      this.getProfile();
    });
  }

}
